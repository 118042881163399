import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		sliders: []
	},
	actions: {
		fetchAllSliders ({ commit }) {
			return window.axios.get('/sliders').then(({ data }) => {
				commit(types.STORE_SLIDER, data)
				return data
			})
		}
		// fetchOneService ({ commit }, { slug }) {
		// 	return window.axios.get(`/products/${slug}`).then(({ data }) => {
		// 		commit(types.STORE_SERVICES, data)
		// 		return data
		// 	})
		// }

	},

	mutations: {
		[types.STORE_SLIDER] (state, sliders) {
			state.sliders = sliders
		}
	}
}
