export const STORE_ABOUT = 'STORE_ABOUT'

export const STORE_SERVICES = 'STORE_SERVICES'

export const STORE_NEWS = 'STORE_NEWS'

export const STORE_ALBUM = 'STORE_ALBUM'

export const STORE_CATEGORIES = 'STORE_CATEGORIES'

export const STORE_CONTACTS = 'STORE_CONTACTS'

export const STORE_VALUES = 'STORE_VALUES'

export const STORE_SLIDER = 'STORE_SLIDER'

export const STORE_SEARCH = 'STORE_SEARCH'
