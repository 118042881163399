<template>
    <section  class="allbum">
        <!-- header -->
        <header v-if="!loading" id="header" :style="{ backgroundImage: `url(${album.primaryImage.url})` }">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div v-if="!loading" class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="text-white pt-5 mt-5"> {{ album.title }} </h1>
                    <div class="line-yellow"></div>
                </div>
				<div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'Home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'gallery' }"
						class="text-decoration-none text-white">
						{{$t('title-page.gallery')}}
						<span class="mx-2"> / </span>
						<span class="fw-bold"> {{album.title}}</span>
					</router-link>
				</div>
            </div>
        </header>

        <!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <section v-if="!loading" class="container container-allbum rounderd-3 d-flex flex-wrap justify-content-center">
            <div
                v-for="(imageAlbum, index) in allbum.images "
                :key="index"
                class="card rounded-4 mx-2 mt-2"
                style="width: 16rem;"
                @click="showMultiple(index)"
            >
                <img :src="imageAlbum.url" width="100%" height="250px" alt="..." class="rounded-3 img my-4">
                <p> {{imageAlbum.caption}} </p>
            </div>
        </section>
		<div>
			<!-- all props & events -->
			<vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible='visible'
				:imgs='imgs'
				:index='index'
				@hide='handleHide'
			></vue-easy-lightbox>
		</div>
    </section>
</template>

<script>
import { mapState } from 'vuex'
// import LightBox from '../components/LightBox.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
// import CoolLightBox from 'vue-cool-lightbox'
// import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
	data () {
		return {
			loading: true,
			album: null,
			imgs: '', // Img Url , string or Array of string
			visible: false,
			index: 0 // default: 0
		}
	},
	components: {
		VueEasyLightbox
	},
	computed: {
		...mapState({
			allbum: state => state.allbum.allbums
		})
	},
	methods: {
		showSingle (index) {
			this.imgs = this.album.images[index].url
			this.show()
		},
		// scroll () {
		// 	window.onscroll = function () {
		// 		console.log(this.scrollY)
		// 	}
		// }
		showMultiple (index) {
			this.imgs = this.album.images.map(c => c.url)
			// allow mixing
			this.index = index
			this.show(index)
		},
		show () {
			this.visible = true
		},
		handleHide () {
			this.visible = false
		}
	},
	created () {
		this.loading = true
		this.$store.dispatch('allbum/fetchOneAllbum', { album: this.$route.params.album }).then((data) => {
			this.album = data
			this.loading = false
		})
	},
	metaInfo () {
		return {
			title: this.$i18n.t('title-page.album') + ' | ' + this.$i18n.t('title-page.gallery') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>

<style scoped lang="scss">
// header
#header {
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
}
// body
.card {
    cursor: pointer;
    background-color: transparent;
	img {
		border: 1px solid grey;
	}
}
.container-allbum {
    animation: transitionIn 0.75s;
}
.spinner {
	height: 100vh
}
// slider
.slider {
    position: absolute;
    z-index: 100;
    height: 100%;
    top: 0%;
    bottom: 0;
    animation: transitionIn 0.75s;
    background-color: rgba(0, 0, 0, 0.473);
}
.bg-grey {
    background-color: rgb(209, 207, 207);
}
.img {
    transition-delay: 0.4s;
    transition: 0.4s ease-in all;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}
</style>
