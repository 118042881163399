import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		search: []
	},
	actions: {
		search ({ commit }, { text }) {
			return window.axios.get('/search', {
				params: {
					text
				}
			}).then(({ data }) => {
				commit(types.STORE_SEARCH, data)
				return data
			})
		}

	},

	mutations: {
		[types.STORE_SEARCH] (state, search) {
			state.search = search
		}
	}
}
