import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		news: []
	},
	actions: {
		fetchAllNews ({ commit }) {
			return window.axios.get('/news').then(({ data }) => {
				commit(types.STORE_NEWS, data)
				return data
			})
		},
		fetchNew ({ commit }, { slug }) {
			return window.axios.get(`/news/${slug}`).then(({ data }) => {
				return data
			})
		}

	},

	mutations: {
		[types.STORE_NEWS] (state, news) {
			state.news = news
		}
	}
}
