import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
	const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: loadLocaleMessages()
})

export function changeI18nLang (lang) {
	if (lang === 'ar' || lang === 'fa') {
		i18n.locale = lang
		document.querySelector('html').dir = 'rtl'
		localStorage.setItem('lang', lang)
	} else if (lang === 'en') {
		i18n.locale = lang
		document.querySelector('html').dir = 'ltr'
		localStorage.setItem('lang', lang)
	}
}
if ('lang' in localStorage) {
	changeI18nLang(localStorage.getItem('lang'))
} else {
	i18n.locale = 'ar'
	document.querySelector('html').dir = 'rtl'
	localStorage.setItem('lang', 'ar')
}
export default i18n
