<template>
    <div>
		<div class="header">
			<header v-if="!loading" id="header" >
				<div class="header  d-flex flex-column align-items-center justify-content-end">
					<div v-if="!loading" class="d-flex h-100 flex-column align-items-center justify-content-center">
						<h1 class="text-white pt-5 mt-5"> {{$t('search')}} </h1>
						<div class="line-yellow"></div>
					</div>
				</div>
			</header>

			<!-- loading -->
			<div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>

			<diV v-if="!loading"  class="container mt-3">
				<!-- news -->
				<p v-if="search.news.length !== 0" class="h3 fw-bold" :class="$i18n.locale === 'en'? 'text-start' : 'text-end' ">{{$t('title-page.news')}} </p>
				<div class="d-flex flex-wrap">
					<diV v-for="(indexNew, index) in search.news" :key="index" class="mx-2">
						<router-link :to="{ name: 'new', params: indexNew.slug }" class="text-decoration-none text-black">
							<resent-card
								:slug="indexNew.slug"
								:cardDate="moment(indexNew.date).format(`YYYY-MM-DD`)"
								:cardTitle="indexNew.title"
								:cardText="indexNew.content"
								:src="indexNew.primaryImage.url"
							></resent-card>
						</router-link>
					</diV>
				</div>

				<p v-if="search.services.length !== 0" class="h3 fw-bold mt-4" :class="$i18n.locale === 'en'? 'text-start' : 'text-end' ">{{$t('title-page.services')}} </p>
				<div class="d-flex flex-wrap ">
					<div id="services" v-for="(service, index) in search.services" :key="index" class="py-4 mx-2">
						<router-link :to="{ name: 'service', params: service.slug }" class="text-decoration-none text-black">
							<card-services
								:title="service.title" :slug="service.slug" :summary="service.summary" :bgImg="service.primaryImage.url" class="mb-2"
							></card-services>
						</router-link>
					</div>
				</div>

				<p v-if="search.albums.length !== 0" class="h3 fw-bold mt-4" :class="$i18n.locale === 'en'? 'text-start' : 'text-end' ">{{$t('title-page.album')}} </p>
				<!-- <div id="Allbum" v-for="(album, index) in search.Albums" :key="index">
				</div> -->
				<div class="d-flex flex-wrap">
					<div v-for="(allbum, index) in search.albums" :key="index" class="mx-2 rounded-4" style="" >
						<router-link :to="{ params: { album: allbum.id}, name: 'allbum' }" class="text-decoration-none">
							<div class="card border-none rounded-4" style="width: 16rem;">
								<img v-if="!loading" :src="allbum.primaryImage.url" width="100%" height="250px" alt="..." class="rounded-3 ">
								<div>
									<h5 class="my-2 "> {{allbum.title}} </h5>
								</div>
							</div>
						</router-link>
					</div>
				</div>

				<div
					v-if="search.albums.length === 0 && search.news.length === 0 && search.services.length === 0"
					class="no-resualte d-flex align-items-center justify-content-center"
				>
					<p class="h3 fw-bold"> {{$t('no-results')}} </p>
				</div>
			</diV>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import ResentCard from '@/components/ResentCard.vue'
import CardServices from '@/components/CardServices.vue'
export default {
	data () {
		return {
			loading: true
		}
	},
	computed: {
		...mapState({
			search: state => state.search.search
		})
	},
	components: {
		ResentCard,
		CardServices
	},
	watch: {
		'$route.query.text' () {
			this.loading = true
			this.$store.dispatch('search/search', { text: this.$route.query.text }).finally(() => {
				this.loading = false
			})
		}
	},
	methods: {
		moment
	},
	created () {
		this.$store.dispatch('search/search', { text: this.$route.query.text }).finally(() => {
			this.loading = false
		})
	}
}
</script>

<style scoped>
.spinner {
	height: 50vh
}
#header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/pexels-erik-mclean-5864152.jpg');
    background-position: center;
    background-size: cover;
}
.card {
	height: 250px;
	background-position: center;
	background-size: cover;
}
.no-resualte {
	width: 100%;
	height: 400px;
	color: rgb(80, 79, 79);
}
</style>
