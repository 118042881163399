<template>
    <div>
        <!-- header -->
        <header class="container-header">
            <div class="container-header__body  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="text-white pt-5 mt-5 h1 fw-bold">{{$t('title-page.gallery')}}</h1>
                    <div class="line-yellow"></div>
                </div>
                <div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.about')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'gallery' }"
						class="text-decoration-none text-white">
						{{$t('title-page.gallery')}}
					</router-link>
				</div>
            </div>
        </header>

        <!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <section v-if="!loading" class="container container-gallery d-flex justify-content-sm-center justify-content-center flex-wrap px-0 my-3">
			<div v-for="(allbum, y) in allbums" :key="y" :album="allbum.id" class="mx-2 my-2 rounded-4 card-gallery" style="" >
				<router-link :to="{ params: { album: allbum.id}, name: 'allbum' }" class="text-decoration-none">
					<div
						class="card border-none rounded-4"
						data-aos="zoom-in"
						data-aos-duration="800"
						data-aos-anchor-placement="top-center"
						data-aos-once="true"
					>
						<img v-if="!loading" :src="allbum.primaryImage.url" width="100%" height="100%" alt="..." class="rounded-3 ">
						<div>
							<h5 class="my-2 "> {{allbum.title}} </h5>
						</div>
					</div>
				</router-link>
			</div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	data () {
		return {
			loading: true
		}
	},
	props: ['album'],
	computed: {
		...mapState({
			allbums: state => state.allbum.allbums
		})
	},
	created () {
		this.loading = true
		this.$store.dispatch('allbum/fetchAllbum').then(() => {
			this.loading = false
		})
	},
	metaInfo () {
		return {
			title: this.$i18n.t('title-page.gallery') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>
<style scoped lang="scss">
/* header */
.container-header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/pexels-erik-mclean-5864152.jpg');
    background-position: center;
    background-size: cover;
    &__body {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.301);
    }
}
/* body */
.container-gallery {
    animation: transitionIn 0.75s;
}
.card-gallery {
	border: 1px solid grey;
}
.spinner {
	height: 50vh
}
.card {
	width: 15.5rem;
	height: 15.5rem;
}
</style>
