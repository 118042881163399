<template>
	<router-link :to="{ params: { slug: slug }, name: 'service' }" class="text-decoration-none">
		<div class="bg-color card-services" :style="{backgroundImage: `url(${bgImg})` }" >
			<div class="black-opacity" style="width:100%; height: 50%"></div>
			<div class=" container-card black-opacity px-4 h-50 d-flex flex-wrap align-items-start justify-content-start" >
				<div class=" w-100 text-white d-flex flex-column align-items-start" :class="$i18n.locale === 'en' ? 'text-start pe-4' : 'text-end ps-4' ">
					<p class="w-100 text-white h5 fw-bold" > {{title}} </p>
					<div class="line-yellow mb-2 "></div>
					<span class="">{{summary.slice(0, 40)}} </span>
					<div class="w-100 mb-3 ">
						<button class="btn px-0 text-white fw-bold fs-sm">{{$t('read-more') + '  >>'}} </button>
					</div>
				</div>
			</div>
		</div>
	</router-link>
</template>
<script>
export default {
	props: ['title', 'slug', 'summary', 'bgImg'],
	data () {
		return {
			// bgImg: '../assets/mikita-yo-OLaa2RO2RRo-unsplash.jpg'
		}
	}
}
</script>

<style lang="scss">
.card-services {
	width: 18rem;
	height: 17.5rem;
	@media only screen and (max-width:300px) {
        width: 15rem;
    }
    @media only screen and (min-width:300px) and (max-width:568px) {
        width: 17rem;
    }
}
.container-card {
	&:hover div span {
		color: #f5cd0b,
	}
}
.black-opacity {
	background-color: rgba(0, 0, 0, 0.452);
}
.bg-color {
	background-position: center;
	background-size: cover;
}
.fs-sm {
	font-size: 14px;
}
</style>
