import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		services: []
	},
	actions: {
		fetchServices ({ commit }) {
			return window.axios.get('/services').then(({ data }) => {
				commit(types.STORE_SERVICES, data)
				return data
			})
		},
		fetchOneService ({ commit }, { slug }) {
			return window.axios.get(`/services/${slug}`).then(({ data }) => data)
		}

	},

	mutations: {
		[types.STORE_SERVICES] (state, services) {
			state.services = services
		}
	}
}
