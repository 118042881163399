<template>
    <nav
		:class="$route.name === 'home' && scrollPosition > 450 || $route.name != 'home'  ? 'change_color' :'bg-transparentt'"
		class="navbar d-flex flex-wrap justify-content-between align-items-center px-xl-5 px-md-3 px-0 py-2 py-sm-0 py-xl-2 w-100 "
	>
        <router-link :to="{ name: 'home' }" class="container-logo d-flex d-xl-flex d-lg-block  text-decoration-none">
            <img src="../assets/rd.png" id="logo" />
			<p class="align-self-center mb-0 font-logo mx-2" style="color: #b48243" >
				{{$t('logo-title')}}
			</p>
        </router-link>

         <div class="align-items-center d-block d-lg-none align-self-center humber-btn">
			<i class="bi bi-list text-white fs-xl"
				@click="showList = !showList"
			></i>
         </div>

        <ul class="P list align-self-center align-items-center mb-0 d-md-block  d-lg-flex" :class="{'show': showList}" >
			<router-link
				:to="{ name: 'home' }"
				class="text-decoration-none"
			>
				<li
					class="mx-lg-3 mx-md-2 text-white fs-nav fw-normal my-3 my-xl-0 "
					:class="$route.name === 'home' ? 'active-link' : '' "
				>
					{{$t('navbar.home')}}
				</li>
			</router-link>

            <router-link
				:to="{ name: 'about' }"
				class="text-decoration-none"
			>
				<li
					class="mx-xl-3 mx-lg-2 mx-md-2 text-white fs-nav fw-normal my-3 my-xl-0"
					:class="$route.name === 'about' ? 'active-link' : '' "
				>
					{{$t('navbar.about')}}
				</li>
			</router-link>

			<div class="dropdown d-lg-flex mb-lg-0 mb-xl-0 d-xl-block">
				<button class="bg-transparent text-white dropdown-toggle mt-0 mb-0 shadow-none border-0 fs-nav fw-normal" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
					{{$t('navbar.services')}}
				</button>
				<ul v-if="requestSuccess" class="dropdown-menu " aria-labelledby="dropdownMenuButton1" :style=" $i18n.locale === 'ar' ? 'width: 300px' : '' ">
					<li v-for="(service, m) in services" :key="m" :class="services.length !== 0 ? 'd-flex flex-column' : 'd-none' " >
						<router-link :to=" { params:{ slug: service.slug }, name: 'service'}" class="dropdown-item text-black onhoverList" :class="$i18n.locale === 'en' ? 'text-center' : 'text-center' ">
							{{service.title}}
						</router-link>
					</li>
				</ul>
			</div>

            <router-link
				:to="{ name: 'news' }"
				class="text-decoration-none"
			>
				<li
					class="mx-lg-3 mx-md-2 text-white fs-nav fw-normal my-3 my-xl-0"
					:class="$route.name === 'news' ? 'active-link' : '' "
				>
					{{$t('navbar.news')}}
				</li>
			</router-link>
            <router-link
				:to="{ name: 'gallery' }"
				class="text-decoration-none"
			>
				<li
					class="mx-lg-3 mx-md-2 text-white fs-nav fw-normal my-3 my-xl-0"
					:class="$route.name === 'gallery' ? 'active-link' : '' "
				>
					{{$t('navbar.gallery')}}
				</li>
			</router-link>

            <router-link
				:to="{ name: 'contact-us' }"
				class="text-decoration-none"
			>
				<li
					class="mx-lg-3 mx-md-2 text-white fs-nav fw-normal my-3 my-xl-0"
					:class="$route.name === 'contact-us' ? 'active-link' : '' "
				>
					{{$t('navbar.contact-us')}}
				</li>
			</router-link>

			<!-- <li>
                <select  v-model="$i18n.locale" @change="$emit('change-lang')" style="border: none; background-color: transparent" class="text-white">
                    <option class="text-black" v-for="(locale, i) in $i18n.availableLocales" :key="`locale${i}`" :value="locale">
                        {{ locale }}
                    </option>
                </select>
            </li> -->
			<!-- <router-link :to="{ name: 'home' }"> -->
				<li class="mx-2" @click="showSearch = true">
					<i class="bi bi-search text-white fw-bold"></i>
				</li>
			<!-- </router-link> -->

			<li class="d-block d-lg-flex">
				<button
					class="lang-btn btn my-0 py-0 px-1"
					@click="$emit('change-lang'); $i18n.locale= 'fa'"
					:class="$i18n.locale === 'fa' ? 'lang-btn-active' : '' "
				>
					<img src="/iran.png" width="30px"/>
				</button>
			</li>

			<li class="d-block d-lg-flex">
				<button
					class="lang-btn btn my-0 py-0 px-1"
					@click="$emit('change-lang'); $i18n.locale= 'ar'"
					:class="$i18n.locale === 'ar' ? 'lang-btn-active' : '' "
				>
					<img src="/united-arab-emirates.png" width="26px"/>
				</button>
			</li>

			<li class="d-block d-lg-flex">
				<!-- <input type="button" /> -->
				<button
					@click="$emit('change-lang'); $i18n.locale= 'en'"
					class="lang-btn btn my-0 py-0 px-1"
					:class="$i18n.locale === 'en' ? 'lang-btn-active' : '' "
				>
					<img src="/united-kingdom.png" width="30px"/>
				</button>
			</li>
        </ul>
		<div v-if="showSearch" class="search search-active">
			<input v-model="text" :placeholder="$t('search')" class="search-box text-white" @keypress.enter="$router.push({
				name: 'search',
				query: { text: text }
			}); showSearch = false; text= null">
			<!-- <router-link class="search-btn btn" :to="{ name: 'search' , query:{ text: text } }" > -->
			<i class="bi bi-search text-white" @click="$router.push({
				name: 'search',
				query: { text: text }
			}); showSearch = false; text= null"></i>
			<!-- </router-link> -->
			<button class="btn-close" @click="showSearch = false"></button>
		</div>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
	data () {
		return {
			showList: false,
			scrollPosition: null,
			requestSuccess: false,
			dirLeft: false,
			showSearch: false,
			text: null
		}
	},
	watch: {
		'$route.path' () {
			this.showList = false
		}
	},
	props: ['load'],
	computed: {
		...mapState({
			services: state => state.services.services
		})
	},
	methods: {
		updateScroll () {
			this.scrollPosition = window.scrollY
		},
		search () {
			this.$store.dispatch('search/search', { params: { query: { text: this.text } } })
		}
	},
	created () {
		this.$store.dispatch('services/fetchServices').then(() => {
			this.requestSuccess = true
		})
	},
	mounted () {
		window.addEventListener('scroll', this.updateScroll)
	}

}
</script>
<style lang="scss">
.onhoverList:hover{
	background: #f5ce0b80;
}
.search {
	display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    // visibility: hidden;
    // opacity: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.877);
    transition: opacity .3s,visibility .3s;
}
.dropdown-menu {
	[dir = 'rtl'] & {
		top: 100%;
		right: 0
	}
}
.search-box {
	border: none;
	border-bottom: 1px solid grey;
	background: transparent;
}
.search-btn {
	font-size: 20px;
	// color:#f5cd0b
}
.btn-close {
	position: absolute;
    top: 20px;
    right: 4%;
    font-size: 1.3rem;
	background-color: white;
}
.font-logo {
	font-size: 15px;
	@media only screen and (max-width:913px) {
		font-size: 9px;
	}
	@media only screen and (min-width:912px) and (max-width: 1200px) {
		font-size: 12px;
	}
}
.change_color {
	background-color:#262827;
	// position: fixed;
}
#navbar {
	width: 100%;
	background-color: rgba(0, 0, 0, 0.678);
	height: auto;
}
.navbar {
	// position: fixed;
	z-index: 10;
	@media only screen and (max-width:992px) {
		height: 75px;
	}
}
.dropdown-menu {
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
}
.P {
    list-style: none;
}
.bg-grey {
	background-color: #262827;
}
.bg-transparentt {
	// position: fixed;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.678);
}
.container-logo {
	@media only screen and (max-width: 440px) {
		padding-left: 10px;
	}
	@media only screen and (min-width: 440px) and (max-width: 992px) {
		padding-left: 10px;
	}
}
#logo {
	width: 55px;
	@media only screen and (min-width: 440px) and (max-width: 992px) {
		width: 60px;
	}
	@media only screen and (max-width: 440px) {
		width: 50px;
	}
}
.list {
    @media only screen and (max-width: 992px) {
		padding: 0;
        background: rgba(0, 0, 0, 0.87);
        width: 100%;
		visibility: hidden;
		transition: all 0.3s ease;
		position: relative;
		// margin-top: 2rem;
    }
}
.show {
	visibility: visible;
	margin: 1.3rem 0;
}
li {
    cursor: pointer;
}
.fs-nav {
    font-size: 15px;
}
.fs-xl {
	font-size: 30px;
}
.humber-btn {
    cursor: pointer;
	@media only screen and (max-width: 440px) {
		padding-right: 10px;
	}
	@media only screen and (min-width: 440px) and (max-width: 992px) {
		padding-right: 10px;
	}
}
.active-link {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid #f5cd0b;
}
.lang-btn {
	transition: 0.3s ease all;
	filter: grayscale(.8);
	&-active {
		filter: grayscale(0);
	}
}
.bg-transparent {
	background-color: transparent;
}
</style>
