import Vue from 'vue'
import Vuex from 'vuex'
import about from '@/store/modules/about'
import services from '@/store/modules/services'
import news from '@/store/modules/news'
import categories from '@/store/modules/categories'
import allbum from '@/store/modules/allbum'
import contacts from '@/store/modules/contacts'
import values from '@/store/modules/values'
import slider from '@/store/modules/slider'
import search from '@/store/modules/search'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		about,
		services,
		news,
		categories,
		allbum,
		contacts,
		values,
		slider,
		search
	}
})
