<template>
	<router-link :to="{ params: { slug: slug }, name: 'new' }" class="text-decoration-none ">
        <div class="card rounded-lg" >
            <img class="card-img-top" :src="src" alt="Card image cap" width="100%" height="200px">
            <div class="card-body">
                <p class="color-yellow mb-0 fw-bold"> {{cardDate}} </p>
                <p class="text-align-start text-black fw-bold h5"> {{cardTitle.slice(0, 20) + '...'}} </p>
                <div v-html="cardText.slice(0, 60)" class="card-text font-light text-grey"></div>
                <a href="#" class="btn btn-outline-secondary py-1 fs-sm"> {{$t('read-more')}} </a>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
	props: ['slug', 'cardTitle', 'cardText', 'cardDate', 'src']
}
</script>
<style lang="scss">
.card {
    transition: 0.4 ease-in all;
    overflow: hidden;
	background-color: rgb(248, 248, 248);
	border: none;
    width: 18rem;
    height: auto;
    position: relative;
    z-index: 1;
    @media only screen and (max-width:300px) {
        width: 15rem;
    }
    @media only screen and (min-width:300px) and (max-width:568px) {
        width: 17rem;
    }
}
.card-img-top {
    transition-delay: 0.1s;
    transition: 0.4 ease-in-out all;
    &:hover {
        transform: scale(1.04);
        transition: 0.4 ease all;
    }
}
.btn-outline-secondary:hover {
    background-color: #f5cd0b;
    border: none
}
.card-body {
	text-align: start;
}
.color-yellow {
	color: #f5cd0b;
}
.fs-sm {
    font-size: 10px;
}
.font-bold {
    font-family: "Montserrat-bold";
}

</style>
