<template>
    <section>
        <!-- header -->
        <header id="header">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="h1 text-white pt-5 mt-5 fw-bold"> {{$t('title-page.about')}} </h1>
                    <div class="line-yellow"></div>
                </div>
				<div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'Home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'about' }"
						class="text-decoration-none text-white">
						{{$t('title-page.about')}}
					</router-link>
				</div>
            </div>
        </header>

        <!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <div v-if="!loading" class="container-about px-sm-5 px-3">
			<!-- d-flex flex-column flex-md-row -->
            <div class="pt-5 pb-2 px-3 px-sm-5  ">
                <div
					class="about-section"
					:class="$i18n.locale === 'en' ? 'text-start' : 'text-end'"
					data-aos="zoom-in"
					data-aos-duration="800"
					data-aos-anchor-placement="top-center"
					data-aos-once="true"
				>
					<div v-html="about.find( c => c.key === 'about')?.value " class="text-center"></div>
                </div>
				<div class="mx-3 d-block container-vision-mission">

					<div class="text-start mx-2">
						<div class="text-center">
							<img src="/blackVision.png" width="50px" class="mb-2 " />
						</div>
						<p class="h6 fw-bold text-center">{{$t('vision')}}</p>
						<div v-html="about.find( c => c.key === 'vision')?.value " class="text-center"></div>
					</div>

					<div class="text-start mx-2 ">
						<div class="text-center">
							<img src="/blackMission.png" width="50px" class="mb-2" />
						</div>
						<p class="h6 fw-bold text-center">{{$t('mission')}}</p>
						<div v-html="about.find( c => c.key === 'mission')?.value " class="text-center"></div>
					</div>

				</div>
            </div>
			<diV
				class="about-section  px-3 px-sm-5"
			>
				<div class="text-center">
					<img src="/blackValues.png" width="50px" class="mb-2 " />
				</div>
				<p class="text-center h6 fw-bold mx-3">{{$t('values')}}<p>
				<div class="d-flex container-values">
					<div v-for="(TheValue, index) in values" :key="index" class="values   "
					:class="$i18n.locale === 'en' ? 'text-start' : 'text-end'">
						<div class="text-center">
							<img :src="TheValue.primaryImage.url" width="25px" class="mb-2 " />
						</div>
						<h4 class="text-black text-center"> {{TheValue.title}} </h4>
						<div v-html="TheValue.content" class="font-light text-black text-center"></div>
					</div>
				</div>
			</div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
	data () {
		return {
			loading: true
		}
	},
	computed: {
		...mapState({
			about: state => state.about.about,
			values: state => state.values.values
		})
	},
	created () {
		Promise.all([
			this.$store.dispatch('about/fetchAbout'),
			this.$store.dispatch('values/fetchAllCompanyValues')]).then(() => {
			this.loading = false
		})
	},
	metaInfo () {
		return {
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
			title: this.$i18n.t('title-page.about') + ' | ' + this.$i18n.t('title-page.home')
		}
	}
}
</script>

<style scoped lang="scss">
/* header */
#header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/max-bender-3rNvnnO7avY-unsplash.jpg');
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.637);
}
/* body */
.values {
	margin: auto 1rem;
}
.container-values {
	@media only screen and (max-width: 992px) {
		flex-wrap: wrap
	}
}
// .container-vision-mission {
// 	@media only screen and (max-width: 992px) {
// 		flex-wrap: wrap
// 	}
// }
.container-about {
    width: 100%;
    background-image: url('../assets/rdBlackAndWhite.png');
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    animation: transitionIn 0.75s;
}
.about-section {
	@media only screen and (max-width: 768px) {
		width: 100%;
	}
}
.spinner {
	height: 50vh
}
.fs-lg {
    font-size: 30px;
}
</style>
