import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Service from '../views/Service.vue'
import About from '../views/About.vue'
import ContactUs from '../views/ContactUs.vue'
import News from '../views/News.vue'
import New from '../views/New.vue'
import Gallery from '../views/Gallery.vue'
import Allbum from '../views/Allbum.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/service/:slug',
		name: 'service',
		component: Service
	},
	{
		path: '/about',
		name: 'about',
		component: About
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		component: ContactUs
	},
	{
		path: '/news',
		name: 'news',
		component: News
	},
	{
		path: '/news/:slug',
		name: 'new',
		component: New
	},
	{
		path: '/gallery',
		name: 'gallery',
		component: Gallery
	},
	{
		path: '/allbum/:album',
		name: 'allbum',
		component: Allbum
	},
	{
		path: '/search',
		name: 'search',
		component: Search
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})

export default router
