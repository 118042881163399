<template>
    <section>
        <header id="header">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 v-if="!loading" class="text-white pt-5 mt-5">{{indexNew.title}}</h1>
                    <div class="line-yellow"></div>
                </div>
                <div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'news' }"
						class="text-decoration-none text-white">
						{{$t('title-page.news')}}
					</router-link>
					<span class="mx-2"> / </span>
					<span v-if="!loading" class="fw-bold"> {{indexNew.title}}</span>
				</div>
            </div>
        </header>

        <!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <section  v-if="!loading" class="container-new px-3 d-flex flex-column flex-md-row justify-content-around">
            <div class="d-flex flex-column col-md-6 col-12 py-3">
                <h3 v-if="!loading" class="text-black" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end' "> {{indexNew.title}} </h3>
                <div class="col-12">
                    <p v-if="!loading" class="fw-bold fs-sm" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end' ">{{ moment(indexNew.date).format(`YYYY-MM-DD`)}}  </p>
                    <img :src="indexNew.primaryImage.url" width="100%" height="400px" class="rounded-4 mx-2 border"  />
                    <div class="d-flex" >
                        <div v-for="(img, index) in indexNew.images.slice(0,6)" :key="index" @click="showMultiple(index)" style="width: 100px; height: 100px" class="my-2 mx-2 position-relative border rounded-4 ">
                            <img :src="img.url" width="100%" height="100%" class="rounded-4 "  />
                            <i v-if="index === 6" class="bi bi-plus position-absolute text-white icon-plus" style=""></i>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- all props & events -->
                    <vue-easy-lightbox
                        escDisabled
                        moveDisabled
                        :visible='visible'
                        :imgs='imgs'
                        :index='index'
                        @hide='handleHide'
                    ></vue-easy-lightbox>
                </div>
                <!-- v-html="indexNew.content.slice(0, indexNew.content.length - 50)" -->
                <div class="col-12" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end' ">
                    <!-- <div style="white-space: pre-wrap;"> -->
                    <div v-html="indexNew.content" class="my-3" ></div>
                    <!-- </div> -->
                    <!-- <p>{{indexNew.content}}</p> -->
                </div>
            </div>

            <!-- more Articles -->
            <div class="card col-md-4 col-12 rounded-3">
                <div class="card-header text-white fw-bold">
                    {{$t('more-new')}}
                </div>
                <div class="card-body" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end' ">
                    <div v-for="(TheNew, index) in news" :key="index">
                        <diV v-if="!loading && index < 5 && $route.params.slug != TheNew.slug">
                            <router-link
                                :to="{ params: { slug: TheNew.slug } }"
                                class="text-decoration-none text-black"
                                @click="fetchById()"
                            >
                                <p
                                    v-if="!loading"
                                    class="fw-bold fs-sm mb-0 text-grey"
                                    :class="$i18n.locale === 'en' ? 'text-start' : 'text-end'"
                                >
                                    {{ moment(TheNew.date).format(`YYYY-MM-DD`)}}
                                </p>
                                <p v-if="!loading"> {{TheNew.title}} </p>
                                <hr v-if=" (news.length - 1) !== index">
                            </router-link>
                        </diV>
                    </div>
                </div>
            </div>

        </section>
    </section>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
	data () {
		return {
			loading: true,
			indexNew: null,
			imgs: '', // Img Url , string or Array of string
			visible: false,
			index: 0 // default: 0
		}
	},
	components: {
		VueEasyLightbox
	},
	watch: {
		'$route.params.slug' (val) {
			if (val) {
				this.loading = true
				this.$store.dispatch('news/fetchNew', { slug: this.$route.params.slug }).then(() => {
					this.$store.dispatch('news/fetchAllNews').then(() => {
						// location.reload()
					}).finally(() => {
						this.loading = false
					})
				})
			}
		}
	},
	methods: {
		moment,
		fetchById () {
			this.$store.dispatch('news/fetchNew', { slug: this.$route.params.slug })
		},
		showMultiple (index) {
			this.imgs = this.indexNew.images.map(c => c.url)
			// allow mixing
			this.index = index
			this.show(index)
		},
		show () {
			this.visible = true
		},
		handleHide () {
			this.visible = false
		}
	},
	computed: {
		...mapState({
			news: state => state.news.news
		})
	},
	created () {
		const promise1 = this.$store.dispatch('news/fetchAllNews').then(() => {
			// this.loading = false
		})
		const promise2 = this.$store.dispatch('news/fetchNew', { slug: this.$route.params.slug }).then((data) => {
			this.indexNew = data
			this.loading = false
		})
		Promise.all([promise1, promise2]).then(() => {
			this.loading = false
		})
	},
	metaInfo () {
		return {
			title: (this.indexNew !== null ? this.indexNew.title : ' ') + ' | ' + this.$i18n.t('title-page.news') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>
<style scoped lang="scss">
#header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/pexels-erik-mclean-5864152.jpg');
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
}
.container-new {
    animation: transitionIn 0.75s;
}
.spinner {
	height: 50vh
}
.icon-plus {
	top: 20%;
	right: 30%;
	font-size: 40px
}
.card {
    width: 350px;
    height: max-content;
    margin-top: 4rem;
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
    }
}
.card-header {
    background-color: #f5cd0b;
}
.border {
    border: 1px solid grey;
}
.card-body div {
    cursor: pointer;
}
.wh-line {
    white-space: break-spaces;
}
</style>
<style >
    span.Y2IQFc {
        white-space: pre-line;
    }
</style>
