import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		values: []
	},
	actions: {
		// get All values
		fetchAllCompanyValues ({ commit }) {
			return window.axios.get('/company-values').then(({ data }) => {
				commit(types.STORE_VALUES, data)
				return data
			})
		}
		// get One Allbum
		// fetchOneAllbum ({ commit }, { album }) {
		// 	return window.axios.get(`/albums/${album}`).then(({ data }) => {
		// 		commit(types.STORE_VALUES, data)
		// 		return data
		// 	})
		// }

	},

	mutations: {
		[types.STORE_VALUES] (state, values) {
			state.values = values
		}
	}
}
