<template>
	<div id="app" :class="$i18n.locale === 'en' ? 'font-montserrat' : 'font-cairo' ">
		<Navbar v-if="!load" id="nav"></Navbar>
		<router-view v-if="!load" />
		<a v-if="!loading && !load" :href=" `https://api.whatsapp.com/send?phone=${branches.find(c => c.isMain).contacts.whatsapp?.slice(1)}` " target="_blank" class="btn position-fixed btn-floating-whatsapp " >
			<img src="/whatsapp.png" width="45px" />
		</a>
		<a v-if="!loading && !load" :href=" `https://t.me/?phone=${branches.find(c => c.isMain).contacts.telegram}` " target="_blank" class="btn position-fixed btn-floating-telegram" >
			<img src="/telegram.png" width="40px" />
		</a>

		<div v-if="load" class="w-100 h-100 position-absolute loading">
			<div class="spinner-border"></div>
		</div>
		<Footer v-if="!load"></Footer>
	</div>
</template>

<script>
import Navbar from '../src/components/Navbar.vue'
import Footer from '../src/components/Footer.vue'
import { mapState } from 'vuex'
import { changeI18nLang } from '@/locales/i18n'

export default {
	data () {
		return {
			loading: true,
			load: false
		}
	},
	components: {
		Navbar,
		Footer
	},
	computed: {
		...mapState({
			branches: state => state.contacts.branches
		})
	},
	watch: {
		'$i18n.locale' (val) {
			this.load = true
			changeI18nLang(val)
			window.axios.defaults.headers['accept-language'] = val
			setTimeout(() => {
				this.load = false
			}, 500)
		}
	},
	created () {
		this.loading = true
		this.$store.dispatch('contacts/fetchAllContacts').finally(() => {
			this.loading = false
		})
	},
	methods: {
		// activLoad () {
		// 	this.load = true
		// 	setTimeout(() => {
		// 		this.load = false
		// 	}, 1000)
		// }
	}
}
</script>

<style lang="scss">
.loading {
	top: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
	z-index: 1000;
}
.btn-yellow {
    background-color: #f5cd0b;
    &:hover {
        background-color: white;
        border: 1px solid #f5cd0b;
    }
}
.line-yellow {
    width: 100px;
    height: 3px;
    background: #f5cd0b;
}
.btn-floating-whatsapp {
	// box-shadow: 10px 10px 10px black;
	right: 10px;
	bottom: 15px;
	z-index: 500;
}
.btn-floating-telegram {
	right: 10px;
	bottom: 70px;
	z-index: 500;
}
.btn:focus {
	outline: none;
	border: none;
}
.text-primary-project {
  color: #f5cd0b
}
.line-yellow {
    width: 100px;
    height: 3px;
    background: #f5cd0b;
}
#app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
//   color: #2c3e50;
}
input {
	outline: none;
}
textarea {
	outline: none;
}
select {
	outline: none;
}

.font-montserrat {
	font-family: 'Montserrat',
}
.font-cairo {
	font-family: 'Cairo'
}
nav {
  padding: 30px;
}
@keyframes transitionIn {
    from {
        opacity: 0;
        transform: rotateX('-10deg');
    }

    to {
        opacity: 1;
        transform: rotateX('0');
    }

}
</style>
