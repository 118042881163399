<template>
    <section>
        <!-- header -->
        <header v-if="!loading" id="header" :style="{ backgroundImage: `url(${service.primaryImage.url})`  }">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="text-white pt-5 mt-5"> {{service.title }} </h1>
                    <div class="line-yellow"></div>
                </div>

				<div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<span class="fw-bold">  {{service.title}} </span>
				</div>
            </div>
        </header>
		<!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <section v-if="!loading" class="container-body px-sm-5 px-3">
			<div v-html="service.content" class="py-5"></div>
            <div class="d-flex flex-wrap justify-content-around" >
                <div
					v-for="(imgServices, index) in service.images"
					:key="index"
					class="size-card my-2 mx-1"
				>
                    <div @click="showSingle(index)" class="container-img mb-2 rounded-4">
                        <img :src="imgServices.url" width="100%" height="100%" class="rounded-4" />
                    </div>
                </div>
            </div>
        </section>

		<div>
			<vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible='visible'
				:imgs='imgs'
				:index='index'
				@hide='handleHide'
			></vue-easy-lightbox>
		</div>

        <!-- <button class="btn btn-yellow my-5">LOAD MORE</button> -->
    </section>

</template>
<script>
import { mapState } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
	data () {
		return {
			service: null,
			loading: true,
			imgs: '',
			visible: false,
			index: 0 // default: 0
		}
	},
	components: {
		VueEasyLightbox
	},
	watch: {
		'$route.params.slug' (val) {
			if (val) {
				this.loading = true
				this.fetchAll()
				// console.log(this.$route.params.slug)
			}
		}
	},
	computed: {
		...mapState({
			services: state => state.services.services
		})
	},
	methods: {
		showSingle (index) {
			this.imgs = this.service.images.map(c => c.url)[index]
			this.show()
		},
		show () {
			this.visible = true
		},
		handleHide () {
			this.visible = false
		},
		fetchAll () {
			this.$store.dispatch('services/fetchOneService', { slug: this.$route.params.slug }).then((data) => {
				this.service = data
				this.loading = false
			})
		}
	},
	created () {
		this.fetchAll()
	},
	metaInfo () {
		return {
			title: (this.service ? this.service.title : '') + ' | ' + this.$i18n.t('title-page.services') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>

<style scoped lang="scss">
// header
#header {
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
    color: rgba(255, 255, 0, 0.5);
}
// body
.container-body {
    animation: transitionIn 0.75s;
}
.spinner {
	height: 100vh
}
.container-img {
	width: 15.5rem;
    height: 15.5rem;
    img {
		border: 1px solid grey;
        object-fit: cover;
        transition-delay: 0.4s;
        transition: 0.4s ease-in all;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
}
.size-card {
    width: 15.5rem;
    height: 15.5rem;
}
</style>
