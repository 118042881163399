import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		categories: []
	},
	actions: {
		// get all categories
		fetchCategories ({ commit }) {
			return window.axios.get('/categories').then(({ data }) => {
				commit(types.STORE_CATEGORIES, data)
				return data
			})
		},
		// get one category
		fetchOneCategory ({ commit }, { slug }) {
			return window.axios.get(`/categories/${slug}`).then(({ data }) => {
				commit(types.STORE_CATEGORIES)
				return data
			})
		}
		// product from category
	},

	mutations: {
		[types.STORE_CATEGORIES] (state, categories) {
			state.categories = categories
		}
	}
}
