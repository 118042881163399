<template>
    <section>
        <!-- header -->
        <header id="header">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="text-white pt-5 mt-5 h1 fw-bold"> {{$t('title-page.news')}} </h1>
                    <div class="line-yellow"></div>
                </div>
				<div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'news' }"
						class="text-decoration-none text-white">
						{{$t('title-page.news')}}
					</router-link>
				</div>
            </div>
        </header>

        <!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- body -->
        <div v-if="!loading" class="container px-0 container-news d-flex flex-wrap justify-content-center  my-5"  >
			<div v-for="(TheNew, i) in news" :key="i"
				data-aos="zoom-in"
				data-aos-duration="800"
				data-aos-anchor-placement="top-center"
				data-aos-once="true"
			>
				<router-link  :to="{ params: { slug: TheNew.slug }, name: 'new' }" class="text-decoration-none" >
					<div :style="{ backgroundImage: `url(${TheNew.primaryImage.url})` }" class="card rounded-4 mx-3 ">
					</div>
					<p class=" text-primary-project fw-bold fs-md text-center my-0"> {{moment(TheNew.date).format(`YYYY-MM-DD`)}} </p>
					<p class="text-black fw-bold h5 mb-0">{{TheNew.title.length <= 20 ? TheNew.title: TheNew.title.slice(0, 7) + '..' }}</p>
                    <p class="text-black">{{ TheNew.summary.length <= 0 ? TheNew.summary : TheNew.summary.slice(0, 30) + '..'}}</p>
				</router-link>
			</div>
        </div>
    </section>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
	data () {
		return {
			loading: false
		}
	},
	methods: {
		moment
	},
	computed: {
		...mapState({
			news: state => state.news.news
		})
	},
	created () {
		if (!this.news.length) {
			this.loading = true
			this.$store.dispatch('news/fetchAllNews').then(() => { this.loading = false })
		}
	},
	metaInfo () {
		return {
			title: this.$i18n.t('title-page.news') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>

<style scoped>
/* header */
#header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/michael-fousert-RNsKphkdBTk-unsplash.jpg');
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.637);
}
/* body */
.container-news {
    animation: transitionIn 0.75s;
}
.spinner {
	height: 50vh
}
.card {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    cursor: pointer;
    border: none;
    width: 15.5rem;
    height: 15.5rem;
	border: 1px solid grey;
}
</style>
