import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		about: []
	},
	actions: {
		fetchAbout ({ commit }) {
			return window.axios.get('/aboutus').then(({ data }) => {
				commit(types.STORE_ABOUT, data)
				return data
			})
		}

	},

	mutations: {
		[types.STORE_ABOUT] (state, about) {
			state.about = about
		}
	}
}
