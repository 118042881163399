import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		allbums: []
	},
	actions: {
		// get All allbums
		fetchAllbum ({ commit }) {
			return window.axios.get('/albums').then(({ data }) => {
				commit(types.STORE_ALBUM, data)
				return data
			})
		},
		// get One Allbum
		fetchOneAllbum ({ commit }, { album }) {
			return window.axios.get(`/albums/${album}`).then(({ data }) => {
				commit(types.STORE_ALBUM, data)
				return data
			})
		}

	},

	mutations: {
		[types.STORE_ALBUM] (state, allbums) {
			state.allbums = allbums
		}
	}
}
