import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18n'
import axios from 'axios'
import VueMeta from 'vue-meta'
import { BASE_URL } from './helpers/constants'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import VueEasyLightbox from 'vue-easy-lightbox'

Vue.use(VueEasyLightbox)

Vue.use(CoolLightBox)

Vue.use(VueMeta)

Vue.config.productionTip = false

window.axios = axios.create({
	baseURL: BASE_URL,
	headers: { 'accept-language': i18n.locale }
	// window.axios.defaults.headers['accept-language'] =  window.localStorage.getItem('lang')
})

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
	mounted () {
		AOS.init()
	}
}).$mount('#app')
