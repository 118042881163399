import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		branches: []
	},
	actions: {
		fetchAllContacts ({ commit }) {
			return window.axios.get('/branches').then(({ data }) => {
				commit(types.STORE_CONTACTS, data)
				return data
			})
		},
		fetchOneBranche ({ commit }, { slug }) {
			return window.axios.get(`/branches/${slug}`).then(({ data }) => {
				commit(types.STORE_CONTACTS, data)
				return data
			})
		},
		feedback ({ commit }, { name, email, type, body, phone }) {
			return window.axios.post('/feedback', { name, email, type, body, phone })
		}
	},

	mutations: {
		[types.STORE_CONTACTS] (state, branches) {
			state.branches = branches
		}
	},

	getters: {
		getBrancheById: state => id => state.branches.find(c => c.id === id)
	}
}
