<template>
	<div>
		<!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
		<section v-if="!loading" class="home">
			<!-- header -->
			<header id="header">
				<!-- Carousel -->
				<vueper-slides autoplay ref="myVueperSlides" dir="ltr">
					<vueper-slide v-for="(slide) in sliders"
						:key="slide.id"
						:content="slide.description"
						:image="slide.image"
					/>
					<template #pause>
						<i class="icon pause_circle_outline"></i>
					</template>
				</vueper-slides>
			</header>

			<!-- about us -->
			<section class="about-section">
				<div class="container-lg px-5 py-5 h-100 d-block d-md-flex justify-center align-items-start ">
					<div
						class="about-section__section-one"
						:class="$i18n.locale === 'en' ? ' text-md-start text-center' :  'text-md-end text-center' "
					>
						<p
							class="h1 text-black fw-bold"
						> {{$t('title.about-us')}} </p>
						<div class="w-100 d-flex justify-content-center d-block d-md-none">
							<div class="line-yellow my-3"></div>
						</div>
						<div class="d-none d-md-block">
							<div class="line-yellow my-3"></div>
						</div>
						<!-- ------------- -->
						<div
							class="font-light text-about"
							:class="$i18n.locale === 'en' ? 'me-md-5' : 'ms-md-5' "
							data-aos="fade-right"
							data-aos-ease="ease"
							data-aos-duration="300"
							data-aos-anchor-placement="top-center"
							data-aos-once="true"
						>
							{{$t('about-text')}}
						</div>
					</div>
					<div class="about-section__section-two my-3" :class="about.find( c => c.key === 'about')?.vlaue? 'align-self-end' : 'align-self-end' ">
						<div
							class=" font-light mt-about"
							:class="$i18n.locale === 'en' ? ' text-md-start text-start' :  'text-md-end text-end' "
						>
							<div
								v-html="about.find( c => c.key === 'about')?.value.trim().split('\n')[0]"
								data-aos="fade-right"
								data-aos-ease="ease"
								data-aos-duration="300"
								data-aos-delay="400"
								data-aos-offset="600px"
								data-aos-anchor-placement="top-center"
								data-aos-once="true"
							></div>
						</div>
						<div
							class="w-100 h-100"
							:class="$i18n.locale === 'en' ? ' text-md-start text-center' :  'text-md-end text-center' "
						>
							<router-link :to="{ name: 'about' }">
								<button class="btn btn-yellow text-black my-2"> {{$t('read-more')}} </button>
							</router-link>
						</div>
					</div>
				</div>
				<p class="h1 text-black fw-bold mb-0 pb-3">{{$t('title.our-services')}}</p>
				<div class="w-100  d-flex justify-content-center pb-3">
					<div class="line-yellow "></div>
				</div>
			</section>
			<div class="services-and-news" >
				<!-- our services -->
				<section class="mb-3 mt-1">
					<div class="services d-flex flex-wrap justify-content-center">
						<div
							v-for="(service, index) in services.filter( c => c.isFeatured )"
							:key="index"
						>
							<card-services
								v-if="index < 8"
								:title="service.title" :slug="service.slug" :summary="service.summary" :bgImg="service.primaryImage.url" class="mb-2"
							></card-services>
						</div>
					</div>
				</section>

				<!-- Resent News -->
				<section class="py-4">
					<p class="h1 text-white fw-bold pb-3 resent-title">{{$t('title.recent-news')}}</p>
					<div class="w-100  d-flex justify-content-center pb-3">
						<div class="line-yellow "></div>
					</div>
					<div class="d-flex flex-wrap justify-content-center container-card-resent">
						<div
							v-for="(Thenew, index) in news.filter( c => c.isFeatured )"
							:key="index"
						>
							<div
								v-if="index < 3"
								class="mx-2 my-2"
								:data-aos="index === 0 ? 'zoom-in-right' : 'zoom-in-left' || index === 1 ? 'zoom-in-up' : 'zoom-in-left'"
								data-aos-duration="500"
								data-aos-anchor-placement="top-center"
								data-aos-once="true"
							>
								<resent-card
									:slug="Thenew.slug"
									:cardDate="moment(Thenew.date).format(`YYYY-MM-DD`)"
									:cardTitle="Thenew.title"
									:cardText="Thenew.content"
									:src="Thenew.primaryImage.url"
								></resent-card>
							</div>

						</div>
					</div>
				</section>

			</div>

			<!-- value section  -->
			<section class="value-section">
				<div class="container-value bg-yellow d-flex flex-wrap justify-content-center align-items-center py-5">
					<div v-for="(TheValue, index) in values" :key="index" class="container-value__card mx-5"
						:class="$i18n.locale === 'en' ? 'text-start' : 'text-end'"
						data-aos="zoom-in"
						data-aos-duration="500"
						data-aos-anchor-placement="top-center"
						data-aos-once="true"
					>
						<img :src="TheValue.primaryImage.url" width="25px" class="mb-3" />
						<p class="text-black fw-bold h5"> {{TheValue.title}} </p>
						<div v-html="TheValue.content" class="font-light text-black"></div>
					</div>
				</div>
			</section>

			<!-- contact -->
			<section class="contact-section d-flex w-100 container my-5 py-3">
				<div class=" h-100 d-flex flex-column align-items-center align-items-lg-start">
					<p
						class="text-black mb-0 h2 fw-bold"
						data-aos="zoom-in"
						data-aos-duration="500"
						data-aos-anchor-placement="top-center"
						data-aos-once="true"
					>
						{{$t('pages.contact.contact-us')}}
					</p>
					<span class="line-yellow my-3"></span>
					<p class="my-3"> {{$t('pages.contact.need-help-call-us')}} </p>
					<a
						dir="ltr"
						:href="`tel: ${contacts.find(c => c.isMain).contacts.mobile}`"
						class="text-primary-project text-decoration-none fw-bold fs-3 mb-4 mt-1"
						data-aos="zoom-in"
						data-aos-duration="500"
						data-aos-anchor-placement="top-center"
						data-aos-once="true"
					>
						<i class="bi bi-telephone-fill"></i>
						{{contacts.find(c => c.isMain).contacts.mobile }}
					</a>
					<p class="text-lg-start text-center" >
						{{contacts.find(c => c.isMain).contacts.address}}
						<span class="text-primary-project fw-bold"></span>
					</p>
					<p data-aos="zoom-in"
						data-aos-duration="500"
						data-aos-anchor-placement="top-center"
						data-aos-once="true"
					> {{$t('pages.contact.we-are-availabl-in')}}
						<router-link class="text-black text-decoration-none fw-bold" :to="{ name: 'contact-us' }">
							{{ contacts.map(c => c.name).join('، ') }}
						</router-link>
					</p>
				</div>
				<div>
					<form class="d-flex flex-column" @submit.prevent="sendFeedback()">
						<diV class="d-block d-md-flex w-100 justify-content-between">
							<!-- <input placeholder="Full Name" class="bg-grey myform my-2 rounded-3 px-4" /> -->
							<input
								v-model="name"
								:placeholder="$t('pages.contact.inputs.placeholder.full-name')"
								type="text"
								class="col-md-5 col-12 bg-grey myform my-2 rounded-3 px-4 "
								:disabled="submitLoading"
							/>

							<input
								v-model="phone"
								:placeholder="$t('pages.contact.inputs.placeholder.phone')"
								type="phone"
								class="col-md-5 col-12 bg-grey myform my-2 rounded-3 px-4 "
								:disabled="submitLoading"
							/>
						</diV>
						<input
							v-model="email"
							:placeholder="$t('pages.contact.inputs.placeholder.email')"
							class="bg-grey myform my-2 rounded-3 px-4"
							:disabled="submitLoading"
						/>
						<select
							v-model="type"
							class="bg-grey myform col-12 bg-grey contact-inputs my-2 rounded-3 px-4"
							aria-label="Default select example"
							:placeholder="$t('pages.contact.inputs.placeholder.interest')"
							style="color: grey"
							:disabled="submitLoading"
						>
							<!-- <option
								class="text-grey hover-select">
								{{ $t('pages.contact.inputs.placeholder.interest') }}
							</option> -->
							<option
								v-for="(service, index) in services"
								:key="index"
								:value="service.title"
							>
								{{service.title }}
							</option>
						</select>
						<textarea
							v-model="body"
							:placeholder="$t('pages.contact.inputs.placeholder.message')"
							class="bg-grey  my-2 rounded-3 px-4"
							:disabled="submitLoading"
						>
						</textarea>
						<button type="submit" class="btn btn-yellow text-black my-2 buttonload">
							<i v-if="submitLoading" class="fa fa-circle-o-notch fa-spin"></i>
							{{$t('send')}}
						</button>
					</form>
				</div>
			</section>
		</section>
	</div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import CardServices from '../components/CardServices.vue'
import ResentCard from '../components/ResentCard.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
	components: { CardServices, ResentCard, VueperSlides, VueperSlide },
	data () {
		return {
			loading: true,
			submitLoading: false,
			name: null,
			phone: null,
			email: null,
			type: null,
			body: null
		}
	},
	methods: {
		moment,
		sendFeedback () {
			this.submitLoading = true
			this.$store.dispatch('contacts/feedback', {
				name: this.name,
				email: this.email,
				type: this.type,
				body: this.body,
				phone: this.phone
			}).then(() => {
				this.name = null
				this.email = null
				this.type = null
				this.body = null
				this.phone = null
			}).finally(() => {
				this.submitLoading = false
			})
		}
	},
	computed: {
		...mapState({
			about: state => state.about.about,
			services: state => state.services.services,
			news: state => state.news.news,
			contacts: state => state.contacts.branches,
			values: state => state.values.values,
			sliders: state => state.slider.sliders
		})
	},

	created () {
		Promise.all([
			this.$store.dispatch('about/fetchAbout'),
			this.$store.dispatch('services/fetchServices'),
			this.$store.dispatch('news/fetchAllNews'),
			this.$store.dispatch('contacts/fetchAllContacts'),
			this.$store.dispatch('values/fetchAllCompanyValues'),
			this.$store.dispatch('slider/fetchAllSliders')
		]).finally(() => {
			this.loading = false
		})
	},
	metaInfo () {
		return {
			title: this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}
}
</script>
<style lang="scss">
	.vueperslide__content {
		padding-top: 1rem;
		padding-bottom: 1rem;
		color: #f5cd0b;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		font-weight: bolder;
		border-top: 2px solid white !important;
		border-bottom: 2px solid white !important;
		padding: 0;
		@media only screen and (min-width: 1300px) and (max-width: 1500px) {
			font-size: 50px;
		}
		@media only screen and (min-width: 1500px) {
			font-size: 60px;
		}
	}
	.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
		height: 100%;
		width: 100%;
		background-color: #16161650;
	}
	.vueperslide__image {
		background-repeat: no-repeat;
	}
</style>
<style lang="scss" scoped>
	// .vueperslide__content {
	// 	border: 2px solid white !important;
	// }
	// .vueperslide__content {
    // border-top: 2px solid;
// }
	.spinner{
		height: 100vh;
	}
    .black-opacity {
        background-color: rgba(0, 0, 0, 0.301);
    }
	#slider-home {
		height: 100%;
		@media only screen and (min-width: 1500px) {
			// height: 80%;
		}
		position: relative;
		z-index: 0
	}
    #header {
        height: 70vh;
		@media only screen and (min-width: 1500px) {
			height: 70vh;
		}
    }
	.carousel-caption {
		bottom: 35%;
	}
	.home {
		animation: transitionIn 0.75s;
	}
    // .title {
	// 	border-top: 2px solid white;
	// 	border-bottom: 2px solid white;
    // }
	.mt-about {
		margin-top: 0;
		@media only screen and (min-width:768px) {
			margin-top: 4rem;
		}
	}
    // about us
    .about-section {
        width: 100%;
        background-image: url('../assets/rdBlackAndWhite.png');
        background-position-x: -6%;
        background-position-y: 68%;
        background-repeat: no-repeat;
        background-size: 25%;
		@media only screen and (min-width: 1500px) {
			background-size: 22.5%;
		}
		@media only screen and (min-width: 1700px) {
			background-size: 20.5%;
		}
        &__section-one{
            width: 50%;
            text-align: center;
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
        };
        &__section-two{
            width: 50%;
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
        }
    }
	.text-about {
		text-align: justify;
		// line-height: 2;
	}
	// services
	.services {
		width: 100%;
		@media only screen and (max-width: 1300px) {
			flex-wrap: wrap;
		}

	}
	// services-and-news
	.services-and-news{
		height: auto;
		width: 100%;
		background: linear-gradient(
			(180deg, #1a1a1a 60%, #fff 60%)
		);
		@media only screen and (max-width: 350px) {
			width: 95%
		}
	}
	// Resent news
	.resent-title {
		background-color: #1a1a1a;
	}
	.container-card-resent {
		position: relative;
		z-index: 1;
	}
	// value-section
	.value-section {
		background-image: url('../assets/ryan-kwok-zr4K6Q13Rrk-unsplash (1).jpg');
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.container-value {
		&__card {
			width: 25%;
			@media only screen and (max-width: 912px) {
				width: 100%;
			}
		}
	}
	.bg-yellow {
		background-color: #f5ce0bd5;
	}
	.myform {
		height: 45px;
		border: 1px solid rgba(155, 155, 155, 0.507);
	}
	.bg-grey {
		background-color: rgba(235, 235, 235, 0.438);
	}
	textarea {
		height: 150px;
		border: 1px solid rgba(155, 155, 155, 0.507);
	}
	.hover-select:hover {
		background: #f5ce0b80;
	}
	.contact-section {
		flex-wrap: wrap;
		div {
			width: 50%;
			@media only screen and (max-width: 992px) {
				width: 100%;
			}
		}
	}
	.font-extra-bold {
		font-family: "Montserrat-ExtraBold";
	}
	.buttonload {
		border: none; /* Remove borders */
		color: white; /* White text */
		padding: 12px 16px; /* Some padding */
		font-size: 16px /* Set a font size */
	}
</style>
