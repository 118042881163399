<template>
	<section>
		<!-- header -->
		<header id="header">
            <div class="header  d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 class="text-white pt-5 mt-5 h1 fw-bold">{{$t('title-page.contact-us')}}</h1>
                    <div class="line-yellow"></div>
                </div>
				<div class="d-flex justify-content-end align-items-end mb-3 fs-sm text-white">
					<router-link :to="{ name: 'home' } "
						class="text-decoration-none text-white"
					>
						{{$t('title-page.home')}}
					</router-link>
					<span class="mx-2"> / </span>
					<router-link :to="{ name: 'contact-us' }"
						class="text-decoration-none text-white">
						{{$t('title-page.contact-us')}}
					</router-link>
				</div>
            </div>
        </header>

		<!-- loading -->
        <div v-if="loading" class="spinner d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
		<!-- body -->
		<div v-if="!loading" class="container-contact">
			<div class="d-flex flex-column flex-md-row  m-5 ">
				<div class=" col-lg-6 col-md-6 col-12 ">
					<h2 class="fw-bold text-black" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end mb-2' ">
						{{$t('pages.contact.get-in-touch')}}
					</h2>
					<p class="h4" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end mb-2' ">
						{{$t('pages.contact.closest-representative-to-you')}}
					<p>

					<div v-for="(branche, index) in contacts" :key="index" class=" ">
						<p
							class="text-primary-project mx-2 fw-bold h5 my-1"
							:class="$i18n.locale === 'en' ? 'text-start' : 'text-end' "
						>
							{{branche.name}}
						</p>
						<div class="d-flex flex-column flex-sm-row flex-md-column flex-lg-row "
							:class="$i18n.locale === 'en'? 'ms-2': 'me-2' "
						>
							<div
								class="widthContact"
								:class="$i18n.locale === 'en' ? 'text-start' : 'text-end' "
							>
								<a
									:href="`mailto: ${ branche.contacts.email}`"
									class="d-block w-100 fw-bold mx-2 my-2 text-decoration-none text-black"
								>
									<i class=" text-primary-project bi bi-envelope-fill mx-1"></i>
									{{
										(!branche.contacts.email ? '-' : branche.contacts.email)
									}}
								</a>

								<p
									class=" fw-bold mx-2 my-2 text-black"
								>
									<i class="bi bi-geo-alt-fill text-primary-project mx-1"></i>
									{{
										(!branche.contacts.address ? '-' : branche.contacts.address)
									}}
								</p>

							</div>

							<div
								class="widthContact"
								:class="$i18n.locale === 'en' ? 'text-start' : 'text-end' "
							>
								<a
									:href="`tel: ${ branche.contacts.mobile}`"
									class=" fw-bold mx-2 my-2 text-decoration-none text-black"
								>
									<i
										class="bi bi-telephone-fill text-primary-project mx-1 ">
									</i>
									<span dir="ltr">
										{{
											(!branche.contacts.mobile ? '-' : branche.contacts.mobile)
										}}
									</span>
								</a>
								<a
									:href="`tel: ${ branche.contacts.phone}`"
									class="d-block fw-bold mx-2 my-2 text-decoration-none text-black"
								>
									<i class="bi bi-telephone-fill text-primary-project mx-1"></i>
									{{
										(!branche.contacts.phone ? '-' : branche.contacts.phone)
									}}
								</a>

							</div>
						</div>
					</div>
					<div class="mt-5" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end' ">
						<a :href="contacts.find(c => c.isMain).contacts.facebook" target="_blank">
							<i
								class="bi bi-facebook  icon-size mx-2"
							></i>
						</a>
						<a :href="`https://t.me/${contacts.find(c => c.isMain).contacts.telegram}`" target="_blank">
							<!-- <i class="bi bi-telegram  icon-size mx-2"></i> -->
							<img src="/telegram.png" width="30px" class="mb-2 mx-1"
							/>
						</a>
						<a :href="contacts.find(c => c.isMain).contacts.instagram" target="_blank">
							<!-- <i class="bi bi-instagram instagram-icon-size mx-2"></i> -->
							<img src="/instagram.png" width="35px"  class="mb-2 mx-1"
							/>
						</a>
						<a :href="contacts.find(c => c.isMain).contacts.pinterest" target="_blank">
							<!-- <i class="bi bi-instagram instagram-icon-size mx-2"></i> -->
							<img src="/pinterest.png" width="30px"  class="mb-2 mx-1"
							/>
						</a>
					</div>
				</div>
				<div class="card-contact col-lg-6 col-md-6 col-12 p-3 rounded-4" >
					<p class="card-contact-title fw-bold px-0">
						{{$t('pages.contact.send-us-message')}}
					</p>
					<form @submit.prevent="" class="w-100">
						<input
							v-model="name"
							:placeholder="$t('pages.contact.inputs.placeholder.full-name')"
							type="text"
							class="col-sm-5 col-11 bg-grey contact-inputs my-2 rounded-3 px-4 "
							:disabled="submitLoading"
						/>
						<input
							v-model="phone"
							:placeholder="$t('pages.contact.inputs.placeholder.phone')"
							type="phone"
							class="col-sm-5 col-11 bg-grey contact-inputs my-2 rounded-3 px-4 "
							:class="$i18n.locale === 'en' ? 'ms-sm-5' : 'me-sm-5'"
							:disabled="submitLoading"
						/>
						<select
							v-model="type"
							class=" col-11 bg-grey contact-inputs my-2 rounded-3 px-4"
							aria-label="Default select example"
							placeholder="Type of message"
							style="color: grey"
							:disabled="submitLoading"
						>
							<option class="text-grey">
								{{$t('pages.contact.inputs.placeholder.interest')}}
							</option>
							<option v-for ="(service, index) in services" :key="index" :value="service.title">
								{{service.title}}
							</option>
						</select>
						<input
							v-model="email"
							:placeholder="$t('pages.contact.inputs.placeholder.email')"
							class="col-11 bg-grey contact-inputs my-2 rounded-3 px-4"
							:disabled="submitLoading"
						/>
						<textarea
							v-model="body"
							:placeholder="$t('pages.contact.inputs.placeholder.interest')"
							class="textarea-style col-11 bg-grey  my-2 rounded-3 px-4"
							:disabled="submitLoading"
						></textarea>
						<button @click="sendFeedback()" class="col-11 btn btn-yellow buttonload">
							<i v-if="submitLoading" class="fa fa-circle-o-notch fa-spin"></i>
							{{$t('send')}}
						</button>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	data () {
		return {
			loading: true,
			submitLoading: false,
			name: null,
			email: null,
			type: null,
			phone: null,
			body: null
		}
	},
	computed: {
		...mapState({
			contacts: state => state.contacts.branches,
			services: state => state.services.services
		}),
		...mapGetters({
			getBrancheById: 'contacts/getBrancheById'
		})
	},
	methods: {
		sendFeedback () {
			this.submitLoading = true
			this.$store.dispatch('contacts/feedback', {
				name: this.name,
				email: this.email,
				type: this.type,
				body: this.body,
				phone: this.phone
			}).then(() => {
				this.name = null
				this.email = null
				this.type = null
				this.body = null
				this.phone = null
			}).finally(() => {
				this.submitLoading = false
			})
		}
	},
	created () {
		this.$store.dispatch('contacts/fetchAllContacts').then(() => {
			this.loading = false
		})
	},
	metaInfo () {
		return {
			title: this.$i18n.t('title-page.contact-us') + ' | ' + this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title')
		}
	}

}
</script>
<style scoped lang="scss">
// header
#header {
    width: 100%;
    height: 50vh;
    background-image: url('../assets/damian-zaleski-RYyr-k3Ysqg-unsplash.jpg');
    background-position: center;
    background-size: cover;
}
.header {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.692);
}
// body
.container-contact {
	background-image: url('../assets/rdBlackAndWhite.png');
	background-position: left;
	background-size: 30%;
	background-repeat: no-repeat;
	animation: transitionIn 0.75s;
	[dir= "rtl"] &{
		background-position: right;
	}
}
.widthContact {
	width: 50%;
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
}
.spinner {
	height: 50vh
}
.contact-inputs {
	height: 45px;
	border: 1px solid rgba(155, 155, 155, 0.507);
}
.textarea-style {
	height: 150px;
	border: 1px solid rgba(155, 155, 155, 0.507);
}
.text-grey {
	color: grey;
}
.bg-grey {
	background-color: rgba(235, 235, 235, 0.438);
	border: none;
}
.card-contact {
	background: transparent;
	// width: 25%;
	border: 1px solid rgba(255, 217, 0, 0.836);
	@media only screen and (min-width:768px) and (max-width: 992px) {
		// width:70%
	}
	@media only screen and (max-width:768px) {
		// width: 100%
	}
}
.icon-size {
	font-size: 30px
}
.instagram-icon-size {
	font-size: 27px;
}
.buttonload {
	border: none; /* Remove borders */
	color: white; /* White text */
	padding: 12px 16px; /* Some padding */
	font-size: 16px /* Set a font size */
}
.btn-yellow:hover {
	background-color: white;
	border: 1px solid #f5cd0b;
	color: #f5cd0b;
}
</style>
