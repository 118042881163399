import { render, staticRenderFns } from "./ResentCard.vue?vue&type=template&id=23d7764c&"
import script from "./ResentCard.vue?vue&type=script&lang=js&"
export * from "./ResentCard.vue?vue&type=script&lang=js&"
import style0 from "./ResentCard.vue?vue&type=style&index=0&id=23d7764c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports